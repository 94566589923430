import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import axios from 'axios'
import { navigate } from 'gatsby'
import { Paper, Snackbar } from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import { logout } from '../dashboard.helpers'

const CreateUserForm = () => {
	const classes = useStyles()

	const [username, setUsername] = useState()
	const [password, setPassword] = useState()
	const [error, setError] = useState('')

	const [showSuccess, setShowSuccess] = React.useState(false)
	const [successMessage, setSuccessMessage] = React.useState('')

	const submit = () => {
		// eslint-disable-next-line no-undef
		const token = window.localStorage.getItem('token')
		if (!token) {
			logout()
			return
		}

		axios({
			method: 'post',
			url: `${process.env.GATSBY_API_URL}/register`,
			data: { username, password },
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
			.then(_ => {
				openSuccess(`Successfully created new user!`)
			})
			.catch(err => {
				if (!err.response.data) {
					return
				}

				switch (err.response.status) {
					case 400:
						setError(err.response.data.error.message)
						break
					default:
						setError('Unknown error occured.')
						break
				}
			})
	}

	const openSuccess = message => {
		setShowSuccess(true)
		setSuccessMessage(message)
	}

	const closeSuccess = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		setShowSuccess(false)
	}

	function Alert(props) {
		return <MuiAlert elevation={6} variant="filled" {...props} />
	}

	return (
		<Paper className={classes.container}>
			<form
				className={classes.form}
				noValidate
				onSubmit={e => {
					e.preventDefault()
					submit()
				}}
			>
				<TextField
					error={error !== ''}
					helperText={error}
					variant="outlined"
					margin="normal"
					required
					fullWidth
					id="username"
					label="Username"
					name="username"
					onChange={e => {
						setUsername(e.target.value)
						setError('')
					}}
				/>
				<TextField
					error={error !== ''}
					helperText={error}
					variant="outlined"
					margin="normal"
					required
					fullWidth
					name="password"
					label="Password"
					type="password"
					id="password"
					autoComplete="current-password"
					onChange={e => {
						setPassword(e.target.value)
						setError('')
					}}
				/>
				<Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
					Create user
				</Button>
			</form>

			<Snackbar open={showSuccess} autoHideDuration={6000} onClose={closeSuccess}>
				<Alert onClose={closeSuccess} severity="success">
					{successMessage}
				</Alert>
			</Snackbar>
		</Paper>
	)
}

export default CreateUserForm

const useStyles = makeStyles(theme => ({
	container: {
		paddingRight: 20,
		paddingLeft: 20,
		paddingBottom: 10
	},
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main
	},
	form: {
		width: '100%' // Fix IE 11 issue.
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	}
}))
