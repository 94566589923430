import { navigate } from 'gatsby'

export const logout = message => {
	// eslint-disable-next-line no-undef
	window.localStorage.removeItem('token')
	// eslint-disable-next-line no-undef
	window.localStorage.removeItem('isAdmin')
	// eslint-disable-next-line no-undef
	window.localStorage.removeItem('userName')
	navigate('/login', {
		state: { error: message }
	})
}

export const getActiveDumpName = dumps => {
	const activeDump = dumps.find(d => d.active == true)
	if (!activeDump) {
		return 'None'
	}
	return activeDump.name
}
