import {
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	Paper,
	Snackbar,
	IconButton
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import MuiAlert from '@material-ui/lab/Alert'
import { logout } from '../dashboard.helpers'
import DeleteIcon from '@material-ui/icons/Delete'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const UserList = () => {
	const classes = useStyles()

	const [token, setToken] = useState()

	const [users, setUsers] = useState([])
	const [userToDelete, setUserToDelete] = useState()
	const [deleteUserIsLoading, setDeleteUserIsLoading] = useState(false)

	const [showSuccess, setShowSuccess] = React.useState(false)
	const [successMessage, setSuccessMessage] = React.useState('')

	const [showError, setShowError] = React.useState(false)
	const [errorMessage, setErrorMessage] = React.useState('')

	const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)

	const openSuccess = message => {
		setShowSuccess(true)
		setSuccessMessage(message)
	}

	const closeSuccess = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		setShowSuccess(false)
	}

	const openError = message => {
		setShowError(true)
		setErrorMessage(message)
	}

	const closeError = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		setShowError(false)
		setErrorMessage('')
	}

	// Load token
	useEffect(() => {
		// eslint-disable-next-line no-undef
		const token = window.localStorage.getItem('token')
		if (!token) {
			logout()
			return
		}
		setToken(token)
	}, [])

	// Fetch dumps
	useEffect(() => {
		updateUsers()
	}, [token])

	const updateUsers = () => {
		if (!token) {
			return
		}
		axios({
			method: 'get',
			url: `${process.env.GATSBY_API_URL}/users`,
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
			.then(res => {
				setUsers(res.data)
			})
			.catch(err => {
				if (err.response.status === 401) {
					logout('Login session expired! Please login again.')
					return
				}

				openError(err.response.message)
			})
	}

	const deleteUser = () => {
		if (!token) {
			return
		}

		setDeleteUserIsLoading(true)

		// Activate dump
		axios({
			method: 'delete',
			url: `${process.env.GATSBY_API_URL}/user/${userToDelete._id}`,
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
			.then(() => {
				openSuccess(`Succesfull deleted user '${userToDelete.username}'`)
				setUsers(users => users.filter(u => u._id !== userToDelete._id))
			})
			.catch(err => {
				if (err.response.status === 401) {
					logout('Login session expired! Please login again.')
					return
				}
				openError(err.response.data.error.message)
			})
			.finally(() => setDeleteUserIsLoading(false))
	}

	const initDelete = (e, user) => {
		e.preventDefault()
		setUserToDelete(user)
		setShowDeleteDialog(true)
	}

	const confirmDelete = e => {
		e.preventDefault()
		setShowDeleteDialog(false)
		deleteUser()
	}

	const cancelDelete = e => {
		e.preventDefault()
		setShowDeleteDialog(false)
	}

	function Alert(props) {
		return <MuiAlert elevation={6} variant="filled" {...props} />
	}

	return (
		<TableContainer component={Paper} className={classes.container}>
			<Table aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>Username</TableCell>
						<TableCell align="left">Admin</TableCell>
						<TableCell align="left"></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{users.map(user => (
						<TableRow key={user._id}>
							<TableCell component="th" scope="row">
								{user.username}
							</TableCell>
							<TableCell align="left">{user.isAdmin ? 'Yes' : 'No'}</TableCell>
							<TableCell align="left">
								{!user.isAdmin && (
									<IconButton onClick={e => initDelete(e, user)}>
										{deleteUserIsLoading && userToDelete._id === user._id ? (
											<CircularProgress color={'secondary'} size={30} />
										) : (
											<DeleteIcon color={'error'} />
										)}
									</IconButton>
								)}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>

			<Snackbar open={showSuccess} autoHideDuration={6000} onClose={closeSuccess}>
				<Alert onClose={closeSuccess} severity="success">
					{successMessage}
				</Alert>
			</Snackbar>
			<Snackbar open={showError} autoHideDuration={6000} onClose={closeError}>
				<Alert onClose={closeError} severity="error">
					{errorMessage}
				</Alert>
			</Snackbar>

			<Dialog
				open={showDeleteDialog}
				onClose={cancelDelete}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				color={'error'}
			>
				<DialogTitle id="alert-dialog-title">{`Delete user '${userToDelete && userToDelete.username}'?`}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">Warning: This cannot be undone!</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button color="primary" onClick={cancelDelete}>
						Cancel
					</Button>
					<Button color="secondary" onClick={confirmDelete} autoFocus>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</TableContainer>
	)
}

export default UserList

const useStyles = makeStyles(theme => ({
	container: {
		position: 'relative',
		overflow: 'visible',
		marginBottom: 20
	}
}))
