import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { AppBar, Container, Toolbar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DumpList from '../components/Dashboard/DumpList'
import { logout } from '../components/Dashboard/dashboard.helpers'
import CreateUserForm from '../components/Dashboard/CreateUser/CreateUser'
import UserList from '../components/Dashboard/UserList/UserList'

const Dashboard = () => {
	const classes = useStyles()

	const [isAdmin, setIsAdmin] = useState(false)

	// Load token
	useEffect(() => {
		// eslint-disable-next-line no-undef
		const isAdmin = window.localStorage.getItem('isAdmin')
		setIsAdmin(isAdmin === 'true' ? true : false)
	}, [])

	return (
		<>
			<AppBar position="static">
				<Toolbar>
					<Typography variant="h6" className={classes.menuTitle}>
						ECU Adaptions Dashboard
					</Typography>
					<Button onClick={logout} color="inherit">
						Logout
					</Button>
				</Toolbar>
			</AppBar>
			<Container maxWidth="sm" className={classes.container}>
				<Typography variant="h5" className={classes.title}>
					Dumps
				</Typography>
				<DumpList />
				{isAdmin && (
					<>
						<Typography variant="h5" className={classes.title}>
							Users
						</Typography>
						<UserList />
						<Typography variant="h5" className={classes.title}>
							Create new user
						</Typography>
						<CreateUserForm />
					</>
				)}
			</Container>
		</>
	)
}

export default Dashboard

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1
	},
	menuButton: {
		marginRight: theme.spacing(2)
	},
	menuTitle: {
		flexGrow: 1,
		fontWeight: 'bold'
	},
	title: {
		flexGrow: 1,
		marginBottom: 20,
		fontWeight: 'bold'
	},
	container: {
		padding: 20
	},
	dumpsContainer: {
		padding: 20
	}
}))
