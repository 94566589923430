import {
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
	TableCell,
	Paper,
	Switch,
	Snackbar,
	IconButton
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import MuiAlert from '@material-ui/lab/Alert'
import { getActiveDumpName, logout } from '../dashboard.helpers'
import DeleteIcon from '@material-ui/icons/Delete'
import Tooltip from '@material-ui/core/Tooltip'
import AddIcon from '@material-ui/icons/Add'
import Fab from '@material-ui/core/Fab'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'

const DumpList = () => {
	const classes = useStyles()

	const [token, setToken] = useState()

	const [dumps, setDumps] = useState([])
	const [dumpToDelete, setDumpToDelete] = useState()
	const [newDumpName, setNewDumpName] = useState('')
	const [createDumpIsLoading, setCreateDumpIsLoading] = useState(false)
	const [deleteDumpIsLoading, setDeleteDumpIsLoading] = useState(false)

	const [showSuccess, setShowSuccess] = React.useState(false)
	const [successMessage, setSuccessMessage] = React.useState('')

	const [showError, setShowError] = React.useState(false)
	const [errorMessage, setErrorMessage] = React.useState('')

	const [showDeleteDialog, setShowDeleteDialog] = React.useState(false)
	const [showCreateDialog, setShowCreateDialog] = React.useState(false)

	const openSuccess = message => {
		setShowSuccess(true)
		setSuccessMessage(message)
	}

	const closeSuccess = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		setShowSuccess(false)
	}

	const openError = message => {
		setShowError(true)
		setErrorMessage(message)
	}

	const closeError = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		setShowError(false)
		setErrorMessage('')
	}

	// Load token
	useEffect(() => {
		// eslint-disable-next-line no-undef
		const token = window.localStorage.getItem('token')
		if (!token) {
			logout()
			return
		}
		setToken(token)
	}, [])

	// Fetch dumps
	useEffect(() => {
		updateDumps()
	}, [token])

	const updateDumps = () => {
		if (!token) {
			return
		}
		axios({
			method: 'get',
			url: `${process.env.GATSBY_API_URL}/dumps`,
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
			.then(res => {
				setDumps(res.data)
			})
			.catch(err => {
				if (err.response.status === 401) {
					logout('Login session expired! Please login again.')
					return
				}

				openError(err.response.message)
			})
	}

	const toggleDump = clickedDump => {
		if (!token) {
			return
		}
		if (clickedDump.active) {
			openError('At least one dump must be active.')
			return
		}

		// Set clicked dump to active and others to in-active
		const oldDumps = [...dumps]
		setDumps(dumps =>
			dumps.map(d => {
				return { ...d, active: d._id === clickedDump._id }
			})
		)

		// Activate dump
		axios({
			method: 'put',
			url: `${process.env.GATSBY_API_URL}/activateDump`,
			params: { dumpId: clickedDump._id },
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
			.then(() => openSuccess(`Succesfull changed active dump to '${getActiveDumpName(dumps)}'`))
			.catch(err => {
				if (err.response.status === 401) {
					logout('Login session expired! Please login again.')
					return
				}
				openError(err.response.data.error.message)
				setDumps(oldDumps)
			})
	}

	const deleteDump = () => {
		if (!token) {
			return
		}

		setDeleteDumpIsLoading(true)

		// Activate dump
		axios({
			method: 'delete',
			url: `${process.env.GATSBY_API_URL}/dump/${dumpToDelete._id}`,
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
			.then(() => {
				openSuccess(`Succesfull deleted dump '${dumpToDelete.name}'`)
				setDumps(dumps => dumps.filter(d => d._id !== dumpToDelete._id))
			})
			.catch(err => {
				if (err.response.status === 401) {
					logout('Login session expired! Please login again.')
					return
				}
				openError(err.response.data.error.message)
			})
			.finally(() => setDeleteDumpIsLoading(false))
	}

	const createDump = () => {
		if (!token) {
			return
		}

		setCreateDumpIsLoading(true)

		// Activate dump
		axios({
			method: 'get',
			url: `${process.env.GATSBY_API_URL}/createScrapeDump`,
			params: {
				dumpName: newDumpName
			},
			headers: {
				Authorization: `Bearer ${token}`
			}
		})
			.then(() => {
				openSuccess(`Succesfull generated new dump '${newDumpName}'`)
				updateDumps()
			})
			.catch(err => {
				if (err?.response?.status === 401) {
					logout('Login session expired! Please login again.')
					return
				}
				openError(err?.response?.data?.error?.message)
			})
			.finally(() => setCreateDumpIsLoading(false))
	}

	const initDelete = (e, dump) => {
		e.preventDefault()
		setDumpToDelete(dump)
		setShowDeleteDialog(true)
	}

	const confirmDelete = e => {
		e.preventDefault()
		setShowDeleteDialog(false)
		deleteDump()
	}

	const cancelDelete = e => {
		e.preventDefault()
		setShowDeleteDialog(false)
	}

	const initCreate = e => {
		e.preventDefault()
		setShowCreateDialog(true)
	}

	const cancelCreate = e => {
		e.preventDefault()
		setShowCreateDialog(false)
	}

	const confirmCreate = e => {
		e.preventDefault()
		setShowCreateDialog(false)
		createDump()
	}

	function Alert(props) {
		return <MuiAlert elevation={6} variant="filled" {...props} />
	}

	return (
		<TableContainer component={Paper} className={classes.container}>
			<Tooltip title="Add" aria-label="add" onClick={initCreate}>
				<Fab color="primary" className={classes.addTooltip} disabled={createDumpIsLoading}>
					{createDumpIsLoading ? <CircularProgress size={30} /> : <AddIcon />}
				</Fab>
			</Tooltip>

			<Table aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>Name</TableCell>
						<TableCell align="left">Created on</TableCell>
						<TableCell align="left">Products</TableCell>
						<TableCell align="center">Active</TableCell>
						<TableCell align="right"></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{dumps.map(dump => (
						<TableRow key={dump._id}>
							<TableCell component="th" scope="row">
								{dump.name}
							</TableCell>
							<TableCell align="left">{moment(Date.parse(dump.createdAt)).format('MMMM Do YYYY, HH:mm')}</TableCell>
							<TableCell align="left">{dump.productCount}</TableCell>
							<TableCell align="center">
								{dump.completed ? (
									<Switch
										checked={dump.active}
										onChange={() => toggleDump(dump)}
										color="primary"
										name="checkedB"
										inputProps={{ 'aria-label': 'primary checkbox' }}
									/>
								) : (
									<CircularProgress size={30} />
								)}
							</TableCell>
							<TableCell align="right">
								<IconButton onClick={e => initDelete(e, dump)}>
									{deleteDumpIsLoading && dumpToDelete._id === dump._id ? (
										<CircularProgress color={'secondary'} size={30} />
									) : (
										<DeleteIcon color={'error'} />
									)}
								</IconButton>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>

			<Snackbar open={showSuccess} autoHideDuration={6000} onClose={closeSuccess}>
				<Alert onClose={closeSuccess} severity="success">
					{successMessage}
				</Alert>
			</Snackbar>
			<Snackbar open={showError} autoHideDuration={6000} onClose={closeError}>
				<Alert onClose={closeError} severity="error">
					{errorMessage}
				</Alert>
			</Snackbar>

			<Dialog
				open={showDeleteDialog}
				onClose={cancelDelete}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				color={'error'}
			>
				<DialogTitle id="alert-dialog-title">{`Delete dump '${dumpToDelete && dumpToDelete.name}'?`}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">Warning: This cannot be undone!</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button color="primary" onClick={cancelDelete}>
						Cancel
					</Button>
					<Button color="secondary" onClick={confirmDelete} autoFocus>
						Delete
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog open={showCreateDialog} onClose={cancelCreate} aria-labelledby="form-dialog-title">
				<DialogTitle id="form-dialog-title">Create new scrape dump</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Generating a scrape dump will take some time. It can take between 1-4 hours, depending on the amount of data
						to be extracted.
					</DialogContentText>
					<TextField
						autoFocus
						margin="dense"
						id="dumpname"
						label="Dump name"
						fullWidth
						onChange={e => {
							setNewDumpName(e.target.value.trim())
						}}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={cancelCreate} color="secondary">
						Cancel
					</Button>
					<Button onClick={confirmCreate} color="primary">
						generate dump
					</Button>
				</DialogActions>
			</Dialog>
		</TableContainer>
	)
}

export default DumpList

const useStyles = makeStyles(theme => ({
	container: {
		position: 'relative',
		overflow: 'visible',
		marginBottom: 20
	},
	addTooltip: {
		position: 'absolute',
		top: -10,
		right: -10,
		width: 50,
		height: 50
	}
}))
